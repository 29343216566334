import locale from "localization";
import styles from "./shift-records-details-sales-inventory.module.scss";
import { Title } from "components/commons";
import { fuelVarianceMapper } from "./shift-records-details-sales-inventory.mapper";
import { fuelVarianceColumn } from "./shift-records-details-sales-inventory-column";
import { formatVolume } from "utils";
import Big from "big.js";
import { ShiftStatus, ProductCodeEnum, FormMode } from "enums";
import { filter, find, isNumber, sumBy } from "lodash";
import useDataTable from "hooks/useDataTable";
import { initialFilterState } from "modules/shift-records/shift-records-filter.state";
import DataTableV2 from "components/commons/data-table/data-table-v2";
import { formatAmount, stringToDecimal } from "utils/text.utils";
import { useEffect, useCallback } from "react";

const ShiftDetailsFuelVariance = ({ shift, progress, form, pageMode }) => {
  const { fields, modifyForm } = form;
  const current = shift?.shift;
  const delivery = shift?.delivery;
  const previous = shift.recentShift;
  const transactionPaymentMethod = shift?.transactionPaymentMethod;
  const transactionItem = shift?.transactionItem;
  const status = shift?.shift.status;
  const isEditMode = pageMode === FormMode.Edit;
  const shiftCompleted = ShiftStatus.Closed === status && progress === 100;

  const isShowValue =
    pageMode === FormMode.Edit || (ShiftStatus.Closed === status && progress === 100);

  let initForm = {
    beginningDipstick: "",
    delivery: 0,
    litersAvailableForSale: "N/A",
    litersSoldPerTotalizer: 0,
    calibration: null,
    returns: "",
    pumpError: "N/A",
    endingInventoryPerTotalizer: 0,
    endingInventoryPerDipstick: "",
    variance: "N/A",
    deliveryVariance: 0,
    totalVariance: "N/A",
    percentVariance: 0,
  };

  let diesel = { ...initForm };
  let gas91 = { ...initForm };
  let gas95 = { ...initForm };
  let gas97 = { ...initForm };

  const computeDeliveryVariance = (e) => {
    return Number(
      Big(e.quantityAfterReceivingL || 0)
        .minus(e.quantityBeforeReceivingL || 0)
        .minus(e.quantityDeliveredL || 0)
    );
  };

  const formatDipstick = (cm, l) => {
    return `${formatVolume(cm, "cm")} / ${formatVolume(l, "L")}`;
  };

  const formatValues = (value, suffix = "L") => {
    if (!isShowValue) {
      return "";
    }

    if (value === "N/A") {
      return "";
    }

    return formatVolume(value, suffix);
  };

  const setEndingFromTotalizer = useCallback(() => {
    modifyForm({
      ...fields,
      [fields.endingDieselCm.name]: {
        value: formatVolume(0, ""),
      },
      [fields.endingGas91Cm.name]: {
        value: formatVolume(0, ""),
      },
      [fields.endingGas95Cm.name]: {
        value: formatVolume(0, ""),
      },
      [fields.endingGas97Cm.name]: {
        value: formatVolume(0, ""),
      },
      [fields.endingDiesel.name]: {
        value: formatVolume(diesel.endingInventoryPerTotalizer, ""),
      },
      [fields.endingGas91.name]: {
        value: formatVolume(gas91.endingInventoryPerTotalizer, ""),
      },
      [fields.endingGas95.name]: {
        value: formatVolume(gas95.endingInventoryPerTotalizer, ""),
      },
      [fields.endingGas97.name]: {
        value: formatVolume(gas97.endingInventoryPerTotalizer, ""),
      },
    });
  }, [
    fields,
    modifyForm,
    diesel.endingInventoryPerTotalizer,
    gas91.endingInventoryPerTotalizer,
    gas95.endingInventoryPerTotalizer,
    gas97.endingInventoryPerTotalizer,
  ]);

  const setBeginningDipstickFromPreviousEnding = useCallback(() => {
    modifyForm({
      ...fields,
      [fields.beginningDieselCm.name]: {
        value: formatVolume(0, ""),
      },
      [fields.beginningGas91Cm.name]: {
        value: formatVolume(0, ""),
      },
      [fields.beginningGas95Cm.name]: {
        value: formatVolume(0, ""),
      },
      [fields.beginningGas97Cm.name]: {
        value: formatVolume(0, ""),
      },
      [fields.beginningDiesel.name]: {
        value: formatVolume(previous.endingDiesel, ""),
      },
      [fields.beginningGas91.name]: {
        value: formatVolume(previous.endingGas91, ""),
      },
      [fields.beginningGas95.name]: {
        value: formatVolume(previous.endingGas95, ""),
      },
      [fields.beginningGas97.name]: {
        value: formatVolume(previous.endingGas97, ""),
      },
    });
  }, [
    fields,
    modifyForm,
    previous.endingDiesel,
    previous.endingGas91,
    previous.endingGas95,
    previous.endingGas97,
  ]);

  useEffect(
    () => {
      if (fields.isReadingDipstickBeginning.value) {
        setBeginningDipstickFromPreviousEnding();
      }
      if (fields.isReadingDipstickEnding.value) {
        setEndingFromTotalizer();
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [fields.isReadingDipstickBeginning.value, fields.isReadingDipstickEnding.value]
  );

  const availableLitersWithoutDipstick = () => {
    diesel.litersAvailableForSale = Number(
      Big(previous.endingDiesel || 0).add(delivery.totalDieselDelivered)
    );
    gas91.litersAvailableForSale = Number(
      Big(previous.endingGas91 || 0).add(delivery.totalGas91Delivered)
    );
    gas95.litersAvailableForSale = Number(
      Big(previous.endingGas95 || 0).add(delivery.totalGas95Delivered)
    );
    gas97.litersAvailableForSale = Number(
      Big(previous.endingGas97 || 0).add(delivery.totalGas97Delivered)
    );
  };

  const setBeginningDipstickFromFields = () => {
    diesel.beginningDipstick = formatDipstick(
      stringToDecimal(fields?.beginningDieselCm.value) || 0,
      stringToDecimal(fields?.beginningDiesel.value) || 0
    );

    gas91.beginningDipstick = formatDipstick(
      stringToDecimal(fields?.beginningGas91Cm.value) || 0,
      stringToDecimal(fields?.beginningGas91.value) || 0
    );

    gas95.beginningDipstick = formatDipstick(
      stringToDecimal(fields?.beginningGas95Cm.value) || 0,
      stringToDecimal(fields?.beginningGas95.value) || 0
    );

    gas97.beginningDipstick = formatDipstick(
      stringToDecimal(fields?.beginningGas97Cm.value) || 0,
      stringToDecimal(fields?.beginningGas97.value) || 0
    );
  };

  const setBeginningDipstickFromPrevious = () => {
    diesel.beginningDipstick = formatDipstick(previous.endingDieselCm, previous.endingDiesel);
    gas91.beginningDipstick = formatDipstick(previous.endingGas91Cm, previous.endingGas91);
    gas95.beginningDipstick = formatDipstick(previous.endingGas95Cm, previous.endingGas95);
    gas97.beginningDipstick = formatDipstick(previous.endingGas97Cm, previous.endingGas97);
  };

  const setBeginningDipstickFromShift = () => {
    diesel.beginningDipstick = formatDipstick(current.beginningDieselCm, current.beginningDiesel);
    gas91.beginningDipstick = formatDipstick(current.beginningGas91Cm, current.beginningGas91);
    gas95.beginningDipstick = formatDipstick(current.beginningGas95Cm, current.beginningGas95);
    gas97.beginningDipstick = formatDipstick(current.beginningGas97Cm, current.beginningGas97);
  };

  const setBeginningDipstick = () => {
    if (isEditMode) {
      if (fields.isReadingDipstickBeginning.value) {
        //checked did not perform
        setBeginningDipstickFromPrevious();
      }

      //user added values
      setBeginningDipstickFromFields();
    }

    if (!isEditMode) {
      if (shiftCompleted) {
        if (!shift.shift.isReadingDipstickBeginning) {
          //current shift did not perform
          setBeginningDipstickFromPrevious();
        }
      } else {
        if (!isShowValue) {
          diesel.beginningDipstick = "";
          gas91.beginningDipstick = "";
          gas95.beginningDipstick = "";
          gas97.beginningDipstick = "";
          return;
        }
      }

      //current shift performed reading
      setBeginningDipstickFromShift();
    }
  };

  const setEndingDipstickFromPrevious = () => {
    diesel.endingInventoryPerDipstick = formatDipstick(
      previous.endingDieselCm,
      previous.endingDiesel
    );
    gas91.endingInventoryPerDipstick = formatDipstick(previous.endingGas91Cm, previous.endingGas91);
    gas95.endingInventoryPerDipstick = formatDipstick(previous.endingGas95Cm, previous.endingGas95);
    gas97.endingInventoryPerDipstick = formatDipstick(previous.endingGas97Cm, previous.endingGas97);
  };

  const setEndingDipstickFromFields = () => {
    diesel.endingInventoryPerDipstick = formatDipstick(
      stringToDecimal(fields?.endingDieselCm.value) || 0,
      stringToDecimal(fields?.endingDiesel.value) || 0
    );

    gas91.endingInventoryPerDipstick = formatDipstick(
      stringToDecimal(fields?.endingGas91Cm.value) || 0,
      stringToDecimal(fields?.endingGas91.value) || 0
    );
    gas95.endingInventoryPerDipstick = formatDipstick(
      stringToDecimal(fields?.endingGas95Cm.value) || 0,
      stringToDecimal(fields?.endingGas95.value) || 0
    );
    gas97.endingInventoryPerDipstick = formatDipstick(
      stringToDecimal(fields?.endingGas97Cm.value) || 0,
      stringToDecimal(fields?.endingGas97.value) || 0
    );
  };

  const setEndingDipstickFromTotalizer = () => {
    diesel.endingInventoryPerDipstick = formatDipstick(0, diesel.endingInventoryPerTotalizer);
    gas91.endingInventoryPerDipstick = formatDipstick(0, gas91.endingInventoryPerTotalizer);
    gas95.endingInventoryPerDipstick = formatDipstick(0, gas95.endingInventoryPerTotalizer);
    gas97.endingInventoryPerDipstick = formatDipstick(0, gas97.endingInventoryPerTotalizer);
  };

  const setEndingDipstickFromShift = () => {
    diesel.endingInventoryPerDipstick = formatDipstick(
      current.endingDieselCm,
      current.endingDiesel
    );
    gas91.endingInventoryPerDipstick = formatDipstick(current.endingGas91Cm, current.endingGas91);
    gas95.endingInventoryPerDipstick = formatDipstick(current.endingGas95Cm, current.endingGas95);
    gas97.endingInventoryPerDipstick = formatDipstick(current.endingGas97Cm, current.endingGas97);
  };

  const setEndingInventoryPerDipstick = () => {
    if (isEditMode) {
      if (fields.isReadingDipstickEnding.value) {
        //checked did not perform
        setEndingDipstickFromTotalizer();
      }

      //user added values
      setEndingDipstickFromFields();
    }

    if (!isEditMode) {
      if (shiftCompleted) {
        if (!shift.shift.isReadingDipstickEnding) {
          //current shift did not perform
          setEndingDipstickFromPrevious();
        }
      } else {
        if (!isShowValue) {
          diesel.endingInventoryPerDipstick = "";
          gas91.endingInventoryPerDipstick = "";
          gas95.endingInventoryPerDipstick = "";
          gas97.endingInventoryPerDipstick = "";
          return;
        }
      }

      //current shift performed reading
      setEndingDipstickFromShift();
    }
  };

  const setDelivery = () => {
    diesel.delivery = delivery.totalDieselDelivered ?? 0;
    gas91.delivery = delivery.totalGas91Delivered ?? 0;
    gas95.delivery = delivery.totalGas95Delivered ?? 0;
    gas97.delivery = delivery.totalGas97Delivered ?? 0;
  };

  const setLitersAvailableForSale = () => {
    if (isEditMode) {
      if (fields.isReadingDipstickBeginning.value) {
        availableLitersWithoutDipstick();
        return;
      }

      diesel.litersAvailableForSale = Number(
        Big(stringToDecimal(fields?.beginningDiesel.value) || 0).add(delivery.totalDieselDelivered)
      );
      gas91.litersAvailableForSale = Number(
        Big(stringToDecimal(fields?.beginningGas91.value) || 0).add(delivery.totalGas91Delivered)
      );
      gas95.litersAvailableForSale = Number(
        Big(stringToDecimal(fields?.beginningGas95.value) || 0).add(delivery.totalGas95Delivered)
      );
      gas97.litersAvailableForSale = Number(
        Big(stringToDecimal(fields?.beginningGas97.value) || 0).add(delivery.totalGas97Delivered)
      );
    }

    if (shiftCompleted) {
      if (!current.isReadingDipstickBeginning) {
        availableLitersWithoutDipstick();
        return;
      }
      diesel.litersAvailableForSale = Number(
        Big(stringToDecimal(current.beginningDiesel) || 0).add(delivery.totalDieselDelivered)
      );
      gas91.litersAvailableForSale = Number(
        Big(stringToDecimal(current.beginningGas91) || 0).add(delivery.totalGas91Delivered)
      );
      gas95.litersAvailableForSale = Number(
        Big(stringToDecimal(current.beginningGas95) || 0).add(delivery.totalGas95Delivered)
      );
      gas97.litersAvailableForSale = Number(
        Big(stringToDecimal(current.beginningGas97) || 0).add(delivery.totalGas97Delivered)
      );
    }
  };

  const setPumpCalibration = () => {
    diesel.calibration =
      find(transactionPaymentMethod.pumpCalibration, (o) => o.name?.toLowerCase() === "diesel")
        ?.quantity || 0.0;
    gas91.calibration =
      find(transactionPaymentMethod.pumpCalibration, (o) => o.name?.toLowerCase() === "gas 91")
        ?.quantity || 0.0;
    gas95.calibration =
      find(transactionPaymentMethod.pumpCalibration, (o) => o.name?.toLowerCase() === "gas 95")
        ?.quantity || 0.0;
    gas97.calibration =
      find(transactionPaymentMethod.pumpCalibration, (o) => o.name?.toLowerCase() === "gas 97")
        ?.quantity || 0.0;
  };

  const setPumpError = () => {
    diesel.pumpError =
      find(transactionPaymentMethod.pumpError, (o) => o.name?.toLowerCase() === "diesel")
        ?.quantity || 0.0;
    gas91.pumpError =
      find(transactionPaymentMethod.pumpError, (o) => o.name?.toLowerCase() === "gas 91")
        ?.quantity || 0.0;
    gas95.pumpError =
      find(transactionPaymentMethod.pumpError, (o) => o.name?.toLowerCase() === "gas 95")
        ?.quantity || 0.0;
    gas97.pumpError =
      find(transactionPaymentMethod.pumpError, (o) => o.name?.toLowerCase() === "gas 97")
        ?.quantity || 0.0;
  };

  const setLitersSoldPerTotalizer = () => {
    const totalDieselSold = Math.abs(
      Big(sumBy(transactionItem?.dieselTransactionItem, (o) => o.endingTotalizer)).minus(
        sumBy(transactionItem?.dieselTransactionItem, (o) => o.beginningTotalizer)
      )
    );
    const totalGas91Sold = Math.abs(
      Big(sumBy(transactionItem?.gas91TransactionItem, (o) => o.endingTotalizer)).minus(
        sumBy(transactionItem?.gas91TransactionItem, (o) => o.beginningTotalizer)
      )
    );
    const totalGas95Sold = Math.abs(
      Big(sumBy(transactionItem?.gas95TransactionItem, (o) => o.endingTotalizer)).minus(
        sumBy(transactionItem?.gas95TransactionItem, (o) => o.beginningTotalizer)
      )
    );
    const totalGas97Sold = Math.abs(
      Big(sumBy(transactionItem?.gas97TransactionItem, (o) => o.endingTotalizer)).minus(
        sumBy(transactionItem?.gas97TransactionItem, (o) => o.beginningTotalizer)
      )
    );

    diesel.litersSoldPerTotalizer = totalDieselSold ?? 0;
    gas91.litersSoldPerTotalizer = totalGas91Sold ?? 0;
    gas95.litersSoldPerTotalizer = totalGas95Sold ?? 0;
    gas97.litersSoldPerTotalizer = totalGas97Sold ?? 0;
  };

  const setDeliveryVariance = () => {
    let deliveries = shift?.delivery.deliveryProducts;
    let dieselTotalVariance = 0;
    let gas91TotalVariance = 0;
    let gas95TotalVariance = 0;
    let gas97TotalVariance = 0;

    filter(deliveries, (e) => {
      const diesel = e.product === ProductCodeEnum.DIESEL;
      if (diesel) {
        dieselTotalVariance = dieselTotalVariance + computeDeliveryVariance(e);
      }
      return diesel;
    });

    filter(deliveries, (e) => {
      const gas91 = e.product === ProductCodeEnum.GAS91;
      if (gas91) {
        gas91TotalVariance = gas91TotalVariance + computeDeliveryVariance(e);
      }
      return gas91;
    });

    filter(deliveries, (e) => {
      const gas95 = e.product === ProductCodeEnum.GAS95;
      if (gas95) {
        gas95TotalVariance = gas95TotalVariance + computeDeliveryVariance(e);
      }
      return gas95;
    });

    filter(deliveries, (e) => {
      const gas97 = e.product === ProductCodeEnum.GAS97;
      if (gas97) {
        gas97TotalVariance = gas97TotalVariance + computeDeliveryVariance(e);
      }
      return gas97;
    });

    diesel.deliveryVariance = dieselTotalVariance;
    gas91.deliveryVariance = gas91TotalVariance;
    gas95.deliveryVariance = gas95TotalVariance;
    gas97.deliveryVariance = gas97TotalVariance;
  };

  const setTotalVariance = () => {
    if (!isShowValue) {
      diesel.totalVariance = "";
      gas91.totalVariance = "";
      gas95.totalVariance = "";
      gas97.totalVariance = "";
      return;
    }

    diesel.totalVariance = Number(Big(diesel?.variance || 0).add(diesel?.deliveryVariance || 0));
    gas91.totalVariance = Number(Big(gas91?.variance || 0).add(gas91?.deliveryVariance || 0));
    gas95.totalVariance = Number(Big(gas95?.variance || 0).add(gas95?.deliveryVariance || 0));
    gas97.totalVariance = Number(Big(gas97?.variance || 0).add(gas97?.deliveryVariance || 0));
  };

  const setTotalizer = () => {
    let dieselTotalizer =
      sumBy(shift?.transactionItem.dieselTransactionItem, "endingTotalizer") || 0;
    let gas91Totalizer = sumBy(shift?.transactionItem.gas91TransactionItem, "endingTotalizer") || 0;
    let gas95Totalizer = sumBy(shift?.transactionItem.gas95TransactionItem, "endingTotalizer") || 0;
    let gas97Totalizer = sumBy(shift?.transactionItem.gas97TransactionItem, "endingTotalizer") || 0;

    const perTotalizer = ({
      litersAvailableForSale,
      litersSoldPerTotalizer,
      calibration,
      pumpError,
    }) => {
      return Math.abs(
        (isNumber(litersAvailableForSale) ? litersAvailableForSale : 0) -
          Big(litersSoldPerTotalizer || 0)
            .minus(isNumber(calibration) ? calibration : 0)
            .minus(isNumber(pumpError) ? pumpError : 0)
      );
    };

    diesel.endingInventoryPerTotalizer = perTotalizer(diesel);
    gas91.endingInventoryPerTotalizer = perTotalizer(gas91);
    gas95.endingInventoryPerTotalizer = perTotalizer(gas95);
    gas97.endingInventoryPerTotalizer = perTotalizer(gas97);

    if (!isShowValue) {
      diesel.variance = "";
      gas91.variance = "";
      gas95.variance = "";
      gas97.variance = "";
      return;
    }

    var dieselLiters = fields?.endingDiesel.value || 0;
    var gas91Liters = fields?.endingGas91.value || 0;
    var gas95Liters = fields?.endingGas95.value || 0;
    var gas97Liters = fields?.endingGas97.value || 0;

    if (isEditMode) {
      dieselLiters = diesel.endingInventoryPerTotalizer;
      gas91Liters = gas91.endingInventoryPerTotalizer;
      gas95Liters = gas95.endingInventoryPerTotalizer;
      gas97Liters = gas97.endingInventoryPerTotalizer;

      dieselTotalizer = fields?.endingDiesel.value || 0;
      gas91Totalizer = fields?.endingGas91.value || 0;
      gas95Totalizer = fields?.endingGas95.value || 0;
      gas97Totalizer = fields?.endingGas97.value || 0;
    }

    if (shiftCompleted) {
      dieselLiters = diesel.endingInventoryPerTotalizer;
      gas91Liters = gas91.endingInventoryPerTotalizer;
      gas95Liters = gas95.endingInventoryPerTotalizer;
      gas97Liters = gas97.endingInventoryPerTotalizer;

      dieselTotalizer = current?.endingDiesel || 0;
      gas91Totalizer = current?.endingGas91 || 0;
      gas95Totalizer = current?.endingGas95 || 0;
      gas97Totalizer = current?.endingGas97 || 0;
    }

    diesel.variance = Number(
      Big(stringToDecimal(dieselTotalizer) || 0).minus(stringToDecimal(dieselLiters))
    ).toFixed(3);
    gas91.variance = Number(
      Big(stringToDecimal(gas91Totalizer) || 0).minus(stringToDecimal(gas91Liters))
    ).toFixed(3);
    gas95.variance = Number(
      Big(stringToDecimal(gas95Totalizer) || 0).minus(stringToDecimal(gas95Liters))
    ).toFixed(3);
    gas97.variance = Number(
      Big(stringToDecimal(gas97Totalizer) || 0).minus(stringToDecimal(gas97Liters))
    ).toFixed(3);
  };

  const setPercentVariance = () => {
    diesel.percentVariance = isNumber(diesel?.totalVariance)
      ? Number(
          Big(diesel?.totalVariance)
            .div(diesel.endingInventoryPerTotalizer || 1)
            .times(100)
        )
      : "N/A";
    gas91.percentVariance = isNumber(gas91?.totalVariance)
      ? Number(
          Big(gas91?.totalVariance)
            .div(gas91.endingInventoryPerTotalizer || 1)
            .times(100)
        )
      : "N/A";
    gas95.percentVariance = isNumber(gas95?.totalVariance)
      ? Number(
          Big(gas95?.totalVariance)
            .div(gas95.endingInventoryPerTotalizer || 1)
            .times(100)
        )
      : "N/A";
    gas97.percentVariance = isNumber(gas97?.totalVariance)
      ? Number(
          Big(gas97?.totalVariance)
            .div(gas97.endingInventoryPerTotalizer || 1)
            .times(100)
        )
      : "N/A";
  };

  const setList = () => {
    let list = [];
    setPumpCalibration();
    setPumpError();
    setDelivery();
    setLitersSoldPerTotalizer();
    setLitersAvailableForSale();

    setDeliveryVariance();
    setTotalizer();
    setTotalVariance();
    setPercentVariance();

    setEndingInventoryPerDipstick();
    setBeginningDipstick();

    list.push(
      {
        ugt: "Beginning Dipstick",
        diesel: diesel.beginningDipstick,
        gas91: gas91.beginningDipstick,
        gas95: gas95.beginningDipstick,
        gas97: gas97.beginningDipstick,
      },
      {
        ugt: "Delivery",
        diesel: formatVolume(diesel.delivery),
        gas91: formatVolume(gas91.delivery),
        gas95: formatVolume(gas95.delivery),
        gas97: formatVolume(gas97.delivery),
      },
      {
        ugt: "Liters Available for Sale",
        diesel: formatValues(diesel.litersAvailableForSale),
        gas91: formatValues(gas91.litersAvailableForSale),
        gas95: formatValues(gas95.litersAvailableForSale),
        gas97: formatValues(gas97.litersAvailableForSale),
      },
      {
        ugt: "Liters Sold per Totalizer",
        diesel: formatVolume(diesel.litersSoldPerTotalizer),
        gas91: formatVolume(gas91.litersSoldPerTotalizer),
        gas95: formatVolume(gas95.litersSoldPerTotalizer),
        gas97: formatVolume(gas97.litersSoldPerTotalizer),
      },
      {
        ugt: "Calibration",
        diesel: formatVolume(diesel.calibration),
        gas91: formatVolume(gas91.calibration),
        gas95: formatVolume(gas95.calibration),
        gas97: formatVolume(gas97.calibration),
      },
      {
        ugt: "Returns",
        diesel: diesel.returns,
        gas91: gas91.returns,
        gas95: gas95.returns,
        gas97: gas97.returns,
      },
      {
        ugt: "Pump Error",
        diesel: formatVolume(diesel.pumpError),
        gas91: formatVolume(gas91.pumpError),
        gas95: formatVolume(gas95.pumpError),
        gas97: formatVolume(gas97.pumpError),
      },
      {
        ugt: "Ending Inventory per Totalizer",
        diesel: formatValues(diesel.endingInventoryPerTotalizer),
        gas91: formatValues(gas91.endingInventoryPerTotalizer),
        gas95: formatValues(gas95.endingInventoryPerTotalizer),
        gas97: formatValues(gas97.endingInventoryPerTotalizer),
      },
      {
        ugt: "Ending Inventory per Dipstick",
        diesel: diesel.endingInventoryPerDipstick,
        gas91: gas91.endingInventoryPerDipstick,
        gas95: gas95.endingInventoryPerDipstick,
        gas97: gas97.endingInventoryPerDipstick,
      },
      {
        ugt: "Variance",
        diesel: formatValues(diesel.variance),
        gas91: formatValues(gas91.variance),
        gas95: formatValues(gas95.variance),
        gas97: formatValues(gas97.variance),
        dieselStyle: [
          styles.content,
          diesel.variance > 0 && styles.positive,
          diesel.variance < 0 && styles.negative,
        ],
        gas91Style: [
          styles.content,
          gas91.variance > 0 && styles.positive,
          gas91.variance < 0 && styles.negative,
        ],
        gas95Style: [
          styles.content,
          gas95.variance > 0 && styles.positive,
          gas95.variance < 0 && styles.negative,
        ],
        gas97Style: [
          styles.content,
          gas97.variance > 0 && styles.positive,
          gas97.variance < 0 && styles.negative,
        ],
      },
      {
        ugt: "Delivery Variance",
        diesel: formatVolume(diesel.deliveryVariance),
        gas91: formatVolume(gas91.deliveryVariance),
        gas95: formatVolume(gas95.deliveryVariance),
        gas97: formatVolume(gas97.deliveryVariance),
      },
      {
        ugt: "Total Variance",
        diesel: formatValues(diesel.totalVariance),
        gas91: formatValues(gas91.totalVariance),
        gas95: formatValues(gas95.totalVariance),
        gas97: formatValues(gas97.totalVariance),
      },
      {
        ugt: "% Variance",
        diesel: isShowValue && formatAmount(diesel.percentVariance, "") + " %",
        gas91: isShowValue && formatAmount(gas91.percentVariance, "") + " %",
        gas95: isShowValue && formatAmount(gas95.percentVariance, "") + " %",
        gas97: isShowValue && formatAmount(gas97.percentVariance, "") + " %",
        dieselStyle: [
          styles.content,
          diesel.percentVariance > 0 && styles.positive,
          diesel.percentVariance < 0 && styles.negative,
        ],
        gas91Style: [
          styles.content,
          gas91.percentVariance > 0 && styles.positive,
          gas91.percentVariance < 0 && styles.negative,
        ],
        gas95Style: [
          styles.content,
          gas95.percentVariance > 0 && styles.positive,
          gas95.percentVariance < 0 && styles.negative,
        ],
        gas97Style: [
          styles.content,
          gas97.percentVariance > 0 && styles.positive,
          gas97.percentVariance < 0 && styles.negative,
        ],
      }
    );

    return list;
  };

  const { table } = useDataTable({
    value: setList(),
    filter: { initialState: initialFilterState(), mapper: (val) => val },
    table: {
      key: "shift",
      name: "fuelVariance",
      mapper: (val) => fuelVarianceMapper({ val }),
      columns: fuelVarianceColumn,
    },
  });

  return (
    <>
      <Title small black>
        {locale.fuelVariance}
      </Title>
      <div className={styles.spacer10} />
      <div className={styles.tableContainer}>
        <DataTableV2 {...table} hideNoSearchResultRender tableVersion="v2" hidePagination />
      </div>
      <div className={styles.spacer50} />
    </>
  );
};

export default ShiftDetailsFuelVariance;
